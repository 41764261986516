






































































































































































































































































































































































import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import PageTitle from '@/components/PageTitle.vue'
import StatisticsHelpers from '@/views/chat/statistics/logic/statisticsHelpers'
import Api from '@/includes/logic/Api'
import { UsersRawData } from './logic/usersStatTypes'
import UsersActivityHeatmap from '@/views/chat/statistics/components/mainStatistics/usersActivityHeatmap.vue'
import UsersLocales from './components/users/usersLocales.vue'
import UsersLogins from './components/users/usersLogins.vue'
import TopActiveUsers from './components/users/topActiveUsers.vue'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'
import TableUserActionButtons from '@/components/TableUserActionButtons.vue'

import LineChart from 'piramis-base-components/src/components/Charts/LineChart/lineChart.vue'
import TableExportButtons from 'piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue'
import { Series } from 'piramis-base-components/src/components/Charts/logic/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import isMobile from 'piramis-js-utils/lib/isMobile'

import moment from 'moment'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { snakeCase } from 'lodash'
import { LineChartData, LineChartOptions } from 'piramis-base-components/src/components/Charts/LineChart/type'

@Component({
  components: {
    TableUserActionButtons,
    UserMainInfo,
    NotAvailableOptionsOverlay,
    UsersActivityHeatmap,
    PageTitle,
    UsersLocales,
    UsersLogins,
    TopActiveUsers,
    TableExportButtons,
    LineChart,
  },
  data() {
    return {
      moment,
      isMobile
    }
  }
})
export default class NewStatisticsUsers extends Mixins<StatisticsHelpers, UseFields, TariffsTagsHelper>(StatisticsHelpers, UseFields, TariffsTagsHelper) {
  loaded = false

  generationDate: string = ''

  users: Array<{ [key: string]: string | number }> | null = null

  rawData: UsersRawData | null = null

  currentUser: any = null

  currentMoreInfoUser: any = null

  showMoreUserInfo: boolean = false

  search = ''

  tableTemplate = 0

  message_count = 0

  get searchResult(): any {
    if (!this.search.length) {
      return this.users
    } else {
      return this.users!
        .filter(user => user.user_login && (user.user_login as string).toLowerCase().includes(this.search.trim().toLowerCase()) ||
          user.user_name && (user.user_name as string).toLowerCase().includes(this.search.trim().toLowerCase()))
    }
  }

  sortDaysWithMessages(a: any, b: any): number {
    if (a.days_with_messages && b.days_with_messages && a.first_message_days && b.first_message_days) {
      return parseFloat(this.calculateDaysWithMessages(b)) - parseFloat(this.calculateDaysWithMessages(a))
    }

    return -1
  }

  sortMessageCountByPeriod(a: any, b: any): number {
    if (a.message_count && b.message_count) {
      return parseInt(b.message_count) - parseInt(a.message_count)
    }

    return -1
  }

  @Watch('showMoreUserInfo')
  showInfoWatcher(value: boolean): void {
    if (!value) {
      this.currentUser = null
    }
  }

  openMoreUserInfoPopup(user_id: number): void {
    const currentUser = this.rawData!.users.find(user => user.user_id === user_id)
    let userActivity: Array<Series> = []
    let labels: Array<string> = []

    if (currentUser) {
      let start = moment().startOf('day')
      let end = moment().endOf('day')

      while (start.isBefore(end)) {
        labels.push(start.format('HH:mm'))
        start.add(1, 'hour')
      }

      Object.entries(currentUser.intraday).forEach(([ key, value ]) => {
        Object.entries(value).forEach(([ activity, value ]) => {
          const findActivity: Series | undefined = userActivity.find(item => item.name === this.$t(`users_statistics_table_${ snakeCase(activity) }`).toString())

          if (findActivity) {
            findActivity.data[Number(key)] = Number(value.toFixed(2))
          } else {
            let newArr = new Array(24).fill(0)
            newArr[0] = Number(value.toFixed(2))

            userActivity.push({
              name: this.$t(`users_statistics_table_${ snakeCase(activity) }`).toString(),
              data: newArr
            })
          }
        })
      })
    }

    this.currentUser = {
      series: {
        series: userActivity,
        labels: labels
      },
      options: {
        chart: {
          xAxis: {
            enabled: true
          },
          yAxis: {
            enabled: true
          },
          sparkline: true,
          height: 400,
          type: 'line',
          grid: {
            y: true,
            x: false
          }
        },
        showInfo: true,
        colors: [ 'rgb(var(--a-success))', 'rgb(var(--a-warning))', 'rgb(var(--a-primary))' ],
        stroke: {
          curve: 'smooth',
          width: 4
        },
        dataLabels: {
          fontSize: '8px',
          fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
          fontWeight: 400
        },
        tooltip: {
          fontSize: '14px',
          fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
          fontWeight: 700,
          postFix: '%'
        }
      }
    }
    this.showMoreUserInfo = true
  }

  getStat(): any {
    if (!this.botIsNotAdmin(this.$store.state.chatState.chat)) {

      Api.getChatUsersStat(this.$store.state.chatState.chat.chat_id)
        .then(res => {
          this.message_count = res.data.message_count
          this.rawData = res.data
          this.users = this.getUsersTable()
        })
    }
  }

  hasUserIntradayStat(userId: number): boolean {
    return !!this.rawData!.users.find(user => user.user_id === userId)?.intraday
  }

  getUserStatistics(user_id: number): { data: LineChartData, options: LineChartOptions } | void {
    let user = this.rawData!.users.find(user => user.user_id === user_id)

    if (user) {
      let labels: Array<string> = []
      const start = moment().startOf('day')
      const end = moment().endOf('day')

      while (start.isBefore(end)) {
        labels.push(start.format('HH:mm'))
        start.add(1, 'hour')
      }

      let data: { [key: string]: Array<number> } = {}
      Object.entries(user.intraday).forEach(([ key, value ]) => {
        const time = labels.findIndex(item => item === (key.length < 2 ? `0${ key }:00` : `${ key }:00`))
        const newArr = new Array(labels.length).fill(0)
        Object.entries(value).forEach(([ activityKey, activityValue ]) => {
          if (data[activityKey]) {
            data[activityKey][time] = Number(activityValue.toFixed(2))
          } else {
            data[activityKey] = newArr.slice()
            data[activityKey][time] = Number(activityValue.toFixed(2))
          }
        })
      })

      let retData: Array<Series> = []

      Object.entries(data).forEach(([ key, value ]) => {
        retData.push({
          name: this.$t(`users_statistics_table_${ snakeCase(key) }`).toString(),
          data: value
        })
      })

      return {
        data: {
          series: [ retData[2] ],
          labels
        },
        options: {
          chart: {
            xAxis: {
              enabled: true
            },
            yAxis: {
              enabled: true
            },
            height: 250,
            type: 'area'
          },
          showInfo: true,
          colors: [ 'rgb(var(--a-success))' ],
          stroke: {
            curve: 'smooth',
            width: 2
          },
          dataLabels: {
            fontSize: '8px',
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontWeight: 400
          },
          chartInfo: {
            fontSize: '14px',
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontWeight: 700,
            postFix: '%'
          }
        }
      }
    }
  }

  getUsersStatisticsOptions() {
    return {
      chart: {
        xAxis: {
          enabled: true
        },
        yAxis: {
          enabled: true
        },
        sparkline: true,
        height: 250,
        type: 'area'
      },
      showInfo: true,
      colors: [ 'rgb(var(--a-success))' ],
      stroke: {
        curve: 'smooth',
        width: 2
      },
      dataLabels: {
        fontSize: '8px',
        fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
        fontWeight: 400
      },
      tooltip: {
        fontSize: '14px',
        fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
        fontWeight: 700,
        postFix: '%'
      }
    }
  }

  calculateDaysWithMessages(record: any): string {
    const procent = (record.days_with_messages / record.first_message_days) * 100

    if (procent > 100) {
      return 100 + '%'
    }

    return procent.toFixed(2) + '%'

  }

  getUsersTable(): Array<any> | null {
    if (this.rawData) {
      const emptyMessageCount = this.rawData.users.slice()
        .filter((user) => !user.message_count)
      // .map(user => {
      //   return {
      //     user_id: user.user_id ? user.user_id : '-',
      //     user_name: user.user_name ? user.user_name : '-',
      //     user_login: user.user_login ? user.user_login : '-',
      //     user_lang: user.user_lang ? user.user_lang : '-',
      //     flood: user.flood ? Number(user.flood.toFixed(2)) : '-',
      //     len_active: user.len_active ? Number(user.len_active.toFixed(2)) : '-',
      //     message_count: user.message_count ? user.message_count : '-',
      //     first_message: user.first_message ? user.first_message : '-',
      //     first_message_days: user.first_message_days ? user.first_message_days : '-',
      //     days_with_messages: user.days_with_messages ? user.days_with_messages : '-',
      //     last_message: user.last_message ? user.last_message : '-',
      //     last_message_days: user.last_message_days ? user.last_message_days : '-',
      //     first_enter: user.first_enter ? user.first_enter : '-',
      //     first_enter_days: user.first_enter_days ? user.first_enter_days : '-',
      //     avatar: user.avatar
      //   }
      // })

      const sorted = this.rawData.users.slice()
        .filter((user) => user.message_count)
        .sort((a, b) => b.message_count - a.message_count)
      // .map(user => {
      //   return {
      //     user_id: user.user_id ? user.user_id : '-',
      //     user_name: user.user_name ? user.user_name : '-',
      //     user_login: user.user_login ? user.user_login : '-',
      //     user_lang: user.user_lang ? user.user_lang : '-',
      //     flood: user.flood ? Number(user.flood.toFixed(2)) : '-',
      //     len_active: user.len_active ? Number(user.len_active.toFixed(2)) : '-',
      //     message_count: user.message_count ? user.message_count : '-',
      //     first_message: user.first_message ? user.first_message : '-',
      //     first_message_days: user.first_message_days ? user.first_message_days : '-',
      //     days_with_messages: user.days_with_messages ? user.days_with_messages : '-',
      //     last_message: user.last_message ? user.last_message : '-',
      //     last_message_days: user.last_message_days ? user.last_message_days : '-',
      //     first_enter: user.first_enter ? user.first_enter : '-',
      //     first_enter_days: user.first_enter_days ? user.first_enter_days : '-',
      //     avatar: user.avatar
      //   }
      // })

      sorted.push(...emptyMessageCount as any)

      return sorted
    }
    return null
  }

  mounted() {
    this.$baseTemplate.saveButton.hide()

    if (this.getTagsByFieldKey('stat_users') === null) {
      this.getStat()
    }
  }

  destroyed() {
    this.$baseTemplate.saveButton.show()
  }
}
