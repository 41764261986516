















import { Component, Prop } from "vue-property-decorator";
import { UseFields } from "piramis-base-components/src/components/Pi";

import Ratio from "piramis-base-components/src/components/Charts/ratio.vue"
import { UsersRawData } from "@/views/chat/statistics/logic/usersStatTypes";
import { RatioParams } from "@/views/chat/statistics/logic/types";

@Component({
  components: {
    Ratio
  }
})
export default class UsersLogins extends UseFields {
  @Prop() rawData!: UsersRawData

  data: RatioParams | null = null

  mounted(): void {
    let haveLogin = 0
    let noLogin = 0

    this.rawData.users.forEach(user => {
      if (user.user_login) {
        haveLogin ++
      } else {
        noLogin ++
      }
    })

    this.data = {
      series: [
        {
          name: this.$t('exists').toString(),
          color: 'var(--a-success)',
          data: haveLogin
        },
        {
          name: this.$t('undefined').toString(),
          color: 'var(--a-danger)',
          data: noLogin
        },
      ]
    }
  }
}
